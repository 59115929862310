import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
// import Image from "../components/image"
import Img from "gatsby-image"
import SEO from "../components/seo"



class Form extends React.Component {

  constructor() {
    super();
    this.submitform = this.submitform.bind(this);
    this.state = {
      className: 'alert alert-info invisible',
      responseMessage: ''
    }
  }
  

  render() {
    return (
      <div>
        <form id="js-form" action="https://j490to7xb2.execute-api.eu-west-1.amazonaws.com/dev/contrast-timbers-mailer" method="POST">
          <div className="field">
            <label className="label" htmlFor="name">Name</label>
            <div className="control">
              <input className="input" name="name" type="text" placeholder="Name..." />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="reply_to">email</label>
            <div className="control">
              <input className="input" name="reply_to" type="email" placeholder="your_email@address.com" />
            </div>
          </div>
          <div className="field verify">
            <label className="label" htmlFor="verify">verify email</label>
            <div className="control">
              <input className="input" name="verify" type="email" placeholder="your_email@address.com" />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="message">Message</label>
            <div className="control">
              <textarea className="textarea" name="message" placeholder="Textarea"></textarea>
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="message">Budget</label>
            <div className="control">
              <label className="radio" for="0-500">
                <input className="radiobutton" type="radio" id="0-500" name="budget" value="0-500" />
                0 - &pound;500</label>
              <label className="radio" for="500-2000">
                <input className="radiobutton" type="radio" id="500-2000" name="budget" value="500-2000" />
                &pound;500 - &pound;2000</label>
              <label className="radio" for="2000-5000">
                <input className="radiobutton" type="radio" id="2000-5000" name="budget" value="2000-5000" />
                &pound;2000 - &pound;5000</label>
              <label className="radio" for="5000-10000">
                <input className="radiobutton" type="radio" id="5000-10000" name="budget" value="5000-10000" />
                &pound;5000 - &pound;10,000</label>
              <label className="radio" for="10000-50000">
                <input className="radiobutton" type="radio" id="10000-50000" name="budget" value="10000-50000" />
                &pound;10,000 - &pound;50,000</label>
              <label className="radio" for="50000+">
                <input className="radiobutton" type="radio" id="50000+" name="budget" value="50000+" />
                &pound;50,000 +</label>
            </div>
          </div>
          <div className="field is-grouped">
            <div className="control">
              <button onClick={this.submitform} className="button is-black">Send</button>
            </div>
          </div>
        </form>
        <div className={this.state.className} role="alert"><p id="js-form-response">{this.state.responseMessage}</p></div>
      </div>
    );
  }

  submitform(e) {
    e.preventDefault();

    const form = e.target.form
    let className = this.state.className;
    let responseMessage = this.state.responseMessage;

    // Prepare data to send
    const formData = {};
    const formElements = Array.from(form);
    formElements.map(input => (formData[input.name] = input.value));

    // Log what our lambda function will receive
    //console.log(JSON.stringify(formData));

    // Construct an HTTP request
    var xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action, true);
    xhr.setRequestHeader('Accept', 'application/json; charset=utf-8');
    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');

    // Send the collected formData as JSON
    xhr.send(JSON.stringify(formData));

    // Callback function
    xhr.onloadend = response => {
      if (response.target.status === 200) {
        // The form submission was successful
        form.reset();
        className = "alert alert-info";
        responseMessage = "Thanks for the message. We'll be in touch shortly.";
      } else {
        // The form submission failed
        // alert.classList.remove("invisible");
        // alert.classList.remove("alert-info");
        // alert.classList.add("alert-danger");
        className = "alert alert-danger";
        responseMessage = 'Something went wrong';
        //console.error(JSON.parse(response.target.response).message);
      }
      this.setState({ className })
      this.setState({ responseMessage })
    };
  }
}



const IndexPage = ({data}) => (
  
  <Layout>
    <SEO title="Home" />
    <div
      className="container"
    >
      <section id="projects">
        <h1><u>Contrast Timbers Furniture.</u></h1>
        
        <div className="columns">
          <div className="column is-one-third">
            <Link to="/projects/domestic">
              <Img fluid={data.domestic.childImageSharp.fluid} />
              <h3><u>Domestic.</u></h3>
            </Link>
          </div>
          <div className="column is-one-third">
            <Link to="/projects/commercial">
              <Img fluid={data.commercial.childImageSharp.fluid} />
              <h3><u>Commercial.</u></h3>
            </Link>
          </div>
          <div className="column is-one-third">
            <Link to="/projects/furniture">
              <Img fluid={data.furniture.childImageSharp.fluid} />
              <h3><u>Furniture.</u></h3>
            </Link>
          </div>
        </div>
      </section>

      <section id="about">
        <div className="columns">
          <div className="column is-one-whole">
            <h2><u>About.</u></h2>
            <div className="about-content">
              <p>Contrast Timbers Furniture design and produce high quality furniture and interiors across both the private and commercial sectors. Our hands-on approach at all stages of the process has enabled us to build a strong reputation, not only for quality furniture, but for quality service too. From our workshop in Bristol, we create individual pieces of furniture, as well as millwork and interiors. We can help you with every room of your home or business, including kitchens, bathrooms, bedrooms, workspaces, dining rooms and everything in between. Every project is unique, and we take the time to tailor each piece of work to the customer’s needs and specifications. This ensures we deliver lovingly crafted furniture which perfectly matches your vision.</p>
              <p>Get in touch with us to discuss your project.</p>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-half is-offset-one-quarter">
            <div className="team-member">
              <Img fluid={data.fred.childImageSharp.fluid} />
              <h3><u>Fred Loosmore.</u></h3>
              <p>Director, designer and maker.</p>
            </div>
          </div>
        </div>
      </section>
      <div className="contact-wrapper">
        <section id="contact">
          <h2><u>Contact.</u></h2>
          <Form />
        </section>
      </div>
    </div>
  </Layout>


)
export const query = graphql`
  query {
    domestic: file(relativePath: { eq: "img/domestic.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    furniture: file(relativePath: { eq: "img/furniture.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
    commercial: file(relativePath: { eq: "img/commercial.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sam: file(relativePath: { eq: "img/sam.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    fred: file(relativePath: { eq: "img/fred.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
        }
      }
    }
  }
`


export default IndexPage
